.ProseMirror {
  color: var(--flame-color-text-primary);
  background-color: var(--flame-color-background);

  &.ProseMirror {
    word-break: normal;
    overflow-wrap: anywhere;
  }

  .is-empty:not(.react-renderer)::before {
    color: var(--flame-color-text-secondary);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  & > * {
    letter-spacing: -0.01em;
  }

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 400;
    font-size: (30rem/16);
    line-height: (36rem/16);
    letter-spacing: -0.02em;
  }

  h3 {
    font-weight: 400;
    font-size: 1.5625rem;
    line-height: 1.625rem;
    letter-spacing: -0.015em;
    font-feature-settings: 'pnum' on, 'onum' on, 'salt' on;
  }

  a {
    color: var(--flame-color-text-primary);
    text-decoration: underline;

    @media (hover: hover) {
      &:hover {
        color: var(--flame-color-text-secondary);
      }
    }
  }

  blockquote {
    font-size: (30rem/16);
    line-height: (36rem/16);
    letter-spacing: -0.02em;
  }

  figure {
    display: flex;
    flex-direction: column;
    align-items: center;

    figcaption {
      margin-top: 20px;
      font-size: (16rem/16);
      line-height: (25rem/16);
      letter-spacing: 0;
      align-self: center;

      &:not(.is-empty) {
        text-align: center;
      }
    }
  }

  .node-picture {
    figure {
      > picture {
        display: flex;
        align-items: center;

        > img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .node-video-iframe {
    figure {
      > div {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        height: 0;
        width: 100%;

        > iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
